import React from "react";
import Service from "../Items/Service";

const servicesData = [
  {
    id: 1,
    name: "Web Development",
    content: "Specializing in creating dynamic websites, enhancing user experience, and ensuring security.",
    icon: "icon-globe",
  },
  {
    id: 2,
    name: "API Integration",
    content: "Expert in integrating REST APIs using JSON to enhance application functionality and ensure secure data exchange.",
    icon: "icon-chemistry",
  },
  {
    id: 3,
    name: "Social Media Marketing",
    content: "Managing social media accounts, preparing and publishing new posts.",
    icon: "icon-directions",
  },
  {
    id: 4,
    name: "Cloud Services",
    content: "Experience with AWS, Oracle Cloud, and other cloud services.",
    icon: "icon-shield",
  },
  {
    id: 5,
    name: "Database Management",
    content: "Database management with SQL, MongoDB, and Redis.",
    icon: "icon-chart",
  },
  {
    id: 6,
    name: "Technical Support",
    content: "Technical support and management on Linux and Windows Server.",
    icon: "icon-bubbles",
  },
];

function Services() {
  return (
    <div className="row -mt-20">
      {servicesData.map((service) => (
        <div className="col-md-4 col-sm-6 mt-20" key={service.id}>
          <Service service={service} />
        </div>
      ))}
    </div>
  );
}

export default Services;
