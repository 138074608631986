import React from "react";
import TrackVisibility from "react-on-screen";
import Skill from "../Items/Skill";

const skillData = {
  skillContent:
    "",
  progressData: [
    {
        id: 1,
        name: "PHP",
        percentage: 100,
    },
    {
        id: 2,
        name: "PHP Framework (Codeigniter, Laravel)",
        percentage: 100,
    },
    {
        id: 3,
        name: "Python",
        percentage: 100,
    },
    {
        id: 4,
        name: "Node.js",
        percentage: 100,
    },
    {
        id: 5,
        name: "JavaScript",
        percentage: 100,
    },
    {
        id: 6,
        name: "JavaScript - jQuery",
        percentage: 100,
    },
    {
        id: 7,
        name: "Vue.js (Basic)",
        percentage: 100,
    },
    
    {
        id: 8,
        name: "SQL (MSSQL, PL/SQL, MySQL)",
        percentage: 100,
    },
    {
        id: 9,
        name: "MongoDB",
        percentage: 100,
    },
    {
        id: 10,
        name: "Redis",
        percentage: 100,
    },
    
    {
        id: 11,
        name: "Canva",
        percentage: 100,
    },
    {
        id: 12,
        name: "Illustrator (Basic)",
        percentage: 100,
    },
    {
        id: 13,
        name: "Adobe Photoshop (Basic Elements)",
        percentage: 100,
    },
    
    {
        id: 14,
        name: "AWS",
        percentage: 100,
    },
    {
        id: 15,
        name: "Oracle Cloud",
        percentage: 100,
    },
    {
        id: 16,
        name: "DigitalOcean",
        percentage: 100,
    },
    {
        id: 17,
        name: "Contabo",
        percentage: 100,
    },
    {
        id: 18,
        name: "Linux",
        percentage: 100,
    },
    {
        id: 19,
        name: "Windows Server",
        percentage: 100,
    },
    {
        id: 20,
        name: "Git & GitHub",
        percentage: 100,
    },
    {
        id: 21,
        name: "JSON",
        percentage: 100,
    },
    {
        id: 22,
        name: "REST API",
        percentage: 100,
    },
    {
        id: 23,
        name: "WHM/cPanel",
        percentage: 100,
    },
    {
        id: 24,
        name: "Plesk",
        percentage: 100,
    },
    {
        id: 25,
        name: "AAPanel",
        percentage: 100,
    },
    {
        id: 26,
        name: "WAMP",
        percentage: 100,
    },
    {
        id: 27,
        name: "XAMPP",
        percentage: 100,
    },
    {
        id: 28,
        name: "Open Server",
        percentage: 100,
    },
    {
        id: 29,
        name: "WordPress CMS",
        percentage: 100,
    },
    {
        id: 30,
        name: "OpenCart CMS",
        percentage: 100,
    },
],
};

function Skills() {
  return (
    <>
      <p className="mb-0">{skillData.skillContent}</p>
      <div className="mt-0">
        <div className="row -mt-50">
          {skillData.progressData.map((progress) => (
            <div className="col-md-6 mt-50" key={progress.id}>
              <TrackVisibility once>
                <Skill progress={progress} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Skills;
